import React from 'react';
import { getMobileDetailInfo } from '../hooks/useMobileSystem';

const CONTENTFUL_URL_PREFIX = 'images.ctfassets.net';
const AVIF_FORMAT = '?fm=avif';
const WEBP_FORMAT = '?fm=webp';

// Versions of iOS and Android that support AVIF and WEBP formats
const PLATFORMS = {
  IOS: {
    WEBP: 14,
    AVIF: 16,
  },
  ANDROID: {
    WEBP: 4,
    AVIF: 12,
  },
};

function getOptimizedContentfulUri(uri) {
  if (!uri || typeof uri !== 'string' || !uri.includes(CONTENTFUL_URL_PREFIX)) {
    return uri;
  }

  const { os, version } = getMobileDetailInfo();
  let affixFormat = '';

  if (
    (os === 'iOS' && version >= PLATFORMS.IOS.WEBP) ||
    (os === 'Android' && version >= PLATFORMS.ANDROID.WEBP)
  ) {
    affixFormat = WEBP_FORMAT;
  }

  if (
    (os === 'iOS' && version >= PLATFORMS.IOS.AVIF) ||
    (os === 'Android' && version >= PLATFORMS.ANDROID.AVIF)
  ) {
    affixFormat = AVIF_FORMAT;
  }

  return `${uri}${affixFormat}`;
}

export default function OptimizedAsset(props) {
  const { src } = props;
  const optimizedSrc = getOptimizedContentfulUri(src);

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...props} src={optimizedSrc} />;
}
